<template>
  <div>
    <!-- Table filters -->
    <v-row class="filer-row">

        <v-spacer v-if="toolbarRight" />

        <v-col cols="12" md="2">
            <SearchTextField
                v-model="selectedSearch"
                :searchFunction="onFilter"
            />
        </v-col>
      
        <v-col cols="12" md="2">
            <v-menu
              ref="menu"
              class="filter-field"
              v-model="showDatePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              color="accent"
              hide-details
              offset-y
            >
                <template v-slot:activator="{ props }">
                  <v-text-field
                    v-bind="props"
                    class="filter-field accent"
                    v-model="selectedDate"
                    label="Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    variant="outlined"
                    clearable
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="selectedDatePicker"
                    @update:modelValue="datePickerSelected"
                    no-title
                ></v-date-picker>
            </v-menu>
        </v-col>

        <SearchButton
            :loading="loading"
            :searchFunction="onFilter"
        />
    </v-row>

    <!-- Data table -->
    <v-data-table
      class="accent-table rounded-table"
      :headers="[
          {title: 'Success', key: 'success', align: 'center' },
          {title: 'Id.', key: 'id', align: 'center'},
          {title: 'Backend uuid', key: 'backend_uuid', align: 'center'},
          {title: 'CDR Id.', key: 'cdr_id', align: 'center'},
          {title: 'Applied tariff', key: 'applied_tariff', align: 'center'},
          {title: 'Error response', key: 'error_response', align: 'center'},
          {title: 'Created at', key: 'created_at', align: 'center'},
          {title: 'Updated at', key: 'updated_at', align: 'center'},
          {title: 'Actions', key: 'actions', align: 'center'}
      ]"
      :items="filteredProcesses"
      :sort-by="[{ key: 'created_at', order: 'desc' }]"
      :search="tableSearch"
      density="compact"
    >
        <template v-slot:item.success="{ item }">
            <v-btn v-if="item.success == null"></v-btn>
            <v-icon v-else-if="item.success" color="success">mdi-check-circle</v-icon>
            <v-icon v-else color="error">mdi-close-circle</v-icon>
        </template>
        <template v-slot:item.backend_uuid="{ item }">
            <v-btn v-if="item.session_id"
              variant="text"  
              :to="`/session/${item.session_id}`" 
            >
              {{item.backend_uuid}}
            </v-btn>
            <span v-else>{{item.backend_uuid}}</span>
        </template>
        <template v-slot:item.applied_tariff="{ item }">
            <v-btn v-if="item.tariff_id"
            variant="text"
              :to="`/tariffs/${item.tariff_id}`" 
            >
              <v-icon left>mdi-tag-text-outline</v-icon> {{item.tariff_id}}
            </v-btn>
        </template>
        <template v-slot:item.error_response="{ item }">
            <json-viewer v-if="item.error_response"
                class="pa-2 text-left"
                :value="item.error_response"
                :expand-depth="0"
                >
            </json-viewer>
        </template>
        <template v-slot:item.created_at="{ item }">
            {{ dateFullFormat(item.created_at)  }}
        </template>
        <template v-slot:item.updated_at="{ item }">
            {{ dateFullFormat(item.updated_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn v-if="!item.success"
            color="accent"  
            @click="retryProcesses(item.id)">
            <v-icon start>mdi-play</v-icon>Retry
          </v-btn>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"

export default {
    name: "cdr-processes",
    components: {
        SearchTextField,
        SearchButton
    },
    props: {
        providerId: {
            type: Number,
        },
        toolbarRight: {
            type: Boolean,
            default: false
        },
    },
    inject: ['$api'],
    data() {
        return {
            processes: [],
            filteredProcesses: [],
            selectedDate: null,
            showDatePicker: false,
            selectedSearch: null,
            selectedDatePicker: null,
            tableSearch: null,
            loading: false,
        }
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
        selectedDate(date){
            this.selectedDatePicker = date ? new Date(date) : new Date()
        }
    },
    methods: {
      async getProcesses(){
        this.loading = true
        //Depending if the provider is looking into EMSP or CPO view 
        const getURL = `cdr_calculation_processes${this.providerId? `?provider_id=${this.providerId}` : ''}`
        await this.$api.get(getURL) 
          .then(resp => {this.processes = resp.data
          })
          .catch(this.showError)
          .finally(() => this.loading = false)
      },
      retryProcesses(id){
        this.$api.post(`cdr_calculation_processes/${id}/retry`)
          .then(() => this.showSuccess('Process has been executed. Refresh table to see result.'))
          .catch(this.showError)
      },
      filterDate(date){
        this.filteredProcesses = this.processes
          .filter(process => (process.created_at && process.created_at.includes(date)) 
            || (process.updated_at && process.updated_at.includes(date)))
      },
      async onFilter(){
        if(this.loading) return
        this.tableSearch = this.selectedSearch
        this.urlPush('search', this.selectedSearch)
        this.urlPush('date', this.selectedDate)

        await this.getProcesses()

        if(!this.selectedDate) { 
                this.selectedDate = null 
                this.filteredProcesses = this.processes
        }else{
            this.filterDate(this.selectedDate)
        }
      },
      datePickerSelected(){
        this.selectedDate = this.dateFormat(this.selectedDatePicker.toISOString())
        this.showDatePicker = false
      },
    },
};
</script>

<style scoped>
  :deep(.jv-code){
    padding: 0!important;
  }
  :deep(.jv-container.jv-light){
    background: transparent !important;
  }
</style>