<template>
    <v-dialog v-model="show" scrollable :max-width="maxWidth">
        <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar color="accent white--text elevation-0">
                        <v-toolbar-title class="headline">{{ title }}</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col v-if="json">
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-btn variant="outlined" @click="copyJson">Copy JSON</v-btn>
                        </v-row>
                        <v-row>
                            <json-viewer
                                :value="json"
                                :expand-depth="expand"
                            >
                            </json-viewer>
                        </v-row>
                    </v-col>
                    <v-col v-if="json2">
                        <v-chip class="mt-5" label v-if="json2.error">
                            <v-icon left>mdi-information-outline</v-icon>
                            {{ json2.error }}
                        </v-chip>
                        <json-viewer
                            v-else
                            :value="json2"
                            :expand-depth="expand"
                        >
                        </json-viewer>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn @click="show = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"

export default {
    data() {
        return {
            show: false,
            title: null,
            json: null,
            json2: null,
            expand: 10,
            maxWidth: 1180
        }
    },
    watch: {
        show( show ) {
            if ( !show ) {
                this.title = null
                this.json = null
                this.json2 = null
                this.expand = 10
                this.maxWidth = 1180
                EventBus().emit("dialog-json:close")
            }
        }
    },
    created() {
        var vm = this;
        EventBus().on("dialog-json:open", async function( args ) {
            
            if( !args.json ){
                vm.showError("Data error, no json")
                return false
            }

            vm.title = args.title
            vm.json = args.json
            vm.json2 = args.json2
            if(vm.expand) { vm.expand = args.expand }
            if(vm.maxWidth) { vm.maxWidth = args.maxWidth }
            vm.show = true

        })
    },
    methods: {
        copyJson() {
            const jsonString = JSON.stringify(this.json, null, 2)
            navigator.clipboard.writeText(jsonString).then(() => {
                this.showSuccess('JSON copied to clipboard')
            }).catch(err => {
                this.showError(`Error copying JSON to clipboard: ${err}`)
            })
        }
    }
}
</script>