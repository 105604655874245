<template>
    <v-container fluid>

        <AppBar :breadcrumbs="[
            { text: 'Providers', to: '/providers' },
            { text: providerName }
        ]" />

        <v-card class="mt-4 mb-4" variant="outlined">
            <v-row class="px-6 mt-1">
                <v-col cols="12" md="6">
                    <v-autocomplete
                        ref="user_autocomplete"
                        v-model="provider.user_id"
                        :items="users"
                        item-value="id"
                        label="User"
                        :custom-filter="dataTableDeepSearch"
                        clearable
                        variant="underlined"
                    >
                        <template v-slot:prepend-item>
                            <v-btn
                                class="append-autocomplete-btn"
                                color="accent"
                                variant="text"
                                block
                                @click="createConnection"
                            >
                                <v-icon left>mdi-plus</v-icon>Create new connection
                            </v-btn>
                        </template>

                        <template v-slot:selection="{ item }">
                            <span>{{ item.raw.username }}</span>
                            <v-chip
                                class="ml-2"
                                v-if="item.raw.type"
                                color="accent"
                                variant="outlined"
                                size="small"
                            >
                                {{ item.raw.type == 'provider' ? 'DIRECT' : item.raw.type.toUpperCase() }}
                            </v-chip>
                            
                        </template>

                        <template v-slot:item="{ props, item }">
                            <v-list-item
                            v-bind="props"
                            title
                            >
                                <span>{{ item.raw.username }}</span>
                                <v-chip
                                    class="ml-2"
                                    v-if="item.raw.type"
                                    color="accent"
                                    variant="outlined"
                                    size="small"
                                >
                                    {{ item.raw.type == 'provider' ? 'DIRECT' : item.raw.type.toUpperCase() }}
                                </v-chip>
                        </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="provider.name"
                        label="Name"
                        hint="Provider Name"
                        variant="underlined"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row class="px-6 my-1">
                <v-col cols="12" md="3">
                    <v-text-field
                        v-model="provider.country_code"
                        label="Country"
                        :rules="[rules.length2]"
                        counter="2"
                        hint="2 characters"
                        variant="underlined"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        v-model="provider.party_id"
                        label="Party"
                        :rules="[rules.length3]"
                        counter="3"
                        hint="3 characters"
                        variant="underlined"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-combobox
                        v-model="selectedRoles"
                        :items="roles"
                        label="Roles"
                        hint="To define a role, it must be done from registration process."
                        multiple
                        small-chips
                        hide-details
                        @update:modelValue="(input) => provider.role = input.join(',')"
                        variant="underlined"
                    >
                        <template v-slot:selection="{item}">
                            <v-chip 
                                size="small" 
                                variant="outlined" 
                                color="accent"
                            >
                                {{ item.value.toUpperCase() }}
                            </v-chip>
                        </template>
                        <template v-slot:item="{ props, item}">
                            <v-list-item  
                                v-bind="props"
                                color="accent"
                                :prepend-icon="selectedRoles.includes(item.value) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                                :title="item.value"
                            />
                        </template>
                    </v-combobox>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        v-model="provider.backend_user_id"
                        label="Backend user ID"
                        variant="underlined"
                    ></v-text-field>
                </v-col>
            </v-row>
    
        </v-card>

        <!-- Flags -->
        <v-row v-if="this.provider.role">
            <v-col cols="3" v-if="hasRole('EMSP')">
                <v-card title="Send evse status" height="100%">
                    <v-card-text height="100%">
                        <p>Si esta activo, enviaremos los cambios de estado de los Evses al Provider</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-checkbox
                            v-model="provider.flag_evse_status"
                            :label="provider.flag_evse_status ? 'Enabled' : 'Disabled'"
                            color="accent"
                            hide-details
                        ></v-checkbox>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="3" v-if="hasRole('EMSP')">
                <v-card title="Send RealTimeAuth" height="100%">
                    <v-card-text height="100%">
                        <p>Al pasar un RFID por uno de nuestros CP, si no sabemos de quien es el RFID, le preguntaremos a este provider si es suyo junto con el resto</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-checkbox
                            v-model="provider.flag_real_time_auth"
                            :label="provider.flag_real_time_auth ? 'Enabled' : 'Disabled'"
                            color="accent"
                            hide-details
                        ></v-checkbox>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="3" v-if="hasRole('EMSP')">
                <v-card title="session.country_code" height="100%">
                    <v-card-text height="100%">
                        <p>Si se marca, cuando se cree y envie el Session al provider, el country_code sera el mismo de donde esta la Location.</p>
                        <p>Por ejemplo si la Location esta en UK, el session.country_code sera GB-WEN en vez de ES-WEN</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-checkbox
                            v-model="provider.flag_session_countrycode_as_location"
                            :label="provider.flag_session_countrycode_as_location ? 'Enabled' : 'Disabled'"
                            color="accent"
                            hide-details
                        ></v-checkbox>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="3" v-if="hasRole('CPO')">
                <v-card title="CronJob - Fetch Locations" height="100%">
                    <v-card-text height="100%">
                        <p>Si se activa, obtendremos cada dia la lista de Locations modificadas del Provider</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-checkbox
                            v-model="provider.flag_fetch_locations_auto"
                            :label="provider.flag_fetch_locations_auto ? 'Enabled' : 'Disabled'"
                            color="accent"
                            hide-details
                        ></v-checkbox>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="3" v-if="hasRole('CPO')">
                <v-card title="CronJob - Fetch CDRs" height="100%">
                    <v-card-text height="100%">
                        <p>Si se activa, obtendremos cada dia la lista de CDRs modificadas del Provider</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-checkbox
                            v-model="provider.flag_fetch_cdrs_auto"
                            :label="provider.flag_fetch_cdrs_auto ? 'Enabled' : 'Disabled'"
                            color="accent"
                            hide-details
                        ></v-checkbox>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="3" v-if="hasRole('CPO')">
                <v-card title="CronJob - Fetch Tariffs" height="100%">
                    <v-card-text height="100%">
                        <p>Si se activa, obtendremos cada dia la lista de Tariffs modificadas del Provider</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-checkbox
                            v-model="provider.flag_fetch_tariffs_auto"
                            :label="provider.flag_fetch_tariffs_auto ? 'Enabled' : 'Disabled'"
                            color="accent"
                            hide-details
                        ></v-checkbox>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="3" v-if="hasRole('EMSP')">
                <v-card title="CronJob - Fetch Tokens" height="100%">
                    <v-card-text height="100%">
                        <p>Si se activa, obtendremos cada dia la lista de Tokens modificadas del Provider.</p>
                        <p>Este CronJob puede consumir muchos recursos dependiendo del Provider ya que pueden ser muchos los Tokens a sincronizar, activarlo solo cuando sea realmente necesario</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-checkbox
                            v-model="provider.flag_fetch_tokens_auto"
                            :label="provider.flag_fetch_tokens_auto ? 'Enabled' : 'Disabled'"
                            color="accent"
                            hide-details
                        ></v-checkbox>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="3" v-if="hasRole('EMSP') || hasRole('NSP')">
                <v-card title="CronJob - Put Locations" height="100%">
                    <v-card-text height="100%">
                        <p>Enviamos nuestro Locations modificados al Provider cada noche</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-checkbox
                            v-model="provider.flag_put_locations_auto"
                            :label="provider.flag_put_locations_auto ? 'Enabled' : 'Disabled'"
                            color="accent"
                            hide-details
                        ></v-checkbox>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <div class="text-right mt-4">
            <v-btn 
                variant="flat"
                color="accent"
                @click="save"
                :disabled="!provider.user_id"
                :prepend-icon="loading ? 'mdi-loading' : 'mdi-content-save'"
            >
                {{ buttonSave }}
            </v-btn>
        </div>

        <CreateConnectionDialog />

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import { EventBus } from "@/event-bus"
import CreateConnectionDialog from "@/components/connection/CreateConnectionDialog"

export default {
    name: "provider.create",
    components: { 
        AppBar, 
        CreateConnectionDialog
    },
    inject: ['$api'],
    data() {
        return {            
            loading: false,
            provider: {},
            users: [],
            roles: ["CPO", "EMSP", "NSP", "HUB"],
            selectedRoles: [],
            rules: {
                length2: v => (v && v.length === 2) || '2 characters are required',
                length3: v => (v && v.length === 3) || '3 characters are required',
            }
        }
    },
    onMounted() {
        EventBus().on("connection-created", ({ user }) => {
            EventBus().emit("dialog-create-connection:close")
            this.users.push(user)
            this.provider.user_id = user.id
        })
    },
    beforeDestroy(){
        EventBus().off('connection-created')
    },
    computed: {
        providerName: function() {
            return this.provider.id ? `${this.provider.country_code}-${this.provider.party_id} (${this.provider.role})` : 'New provider'
        },
        buttonSave: function() { return this.provider.id ? 'Save' : 'Create provider' }
    },
    async mounted(){

        this.users = await this.$api.get(`/connections`).then(resp => resp.data)
        let id = this.$route.params.id
        if( !id ){ return }

        this.loading = true

        try {

            let response = await this.$api.get(`/provider/${id}`)
            this.provider = response.data
            this.selectedRoles = this.provider && this.provider.role ? this.provider.role.split(',') : []
            this.user = response.data.user

        } catch(e) {
            this.showError(e)
        }

        this.loading = false
    },
    methods: {
        save(){
            let data = (({ user, tokens, company, ...provider }) => provider)(this.provider)

            if( this.provider.id ){

                this.$api.put(`/provider/${this.provider.id}`, data)
                    .then(resp => {
                        this.provider = resp.data
                        this.showSuccess("Data saved successfully")
                    })
                    .catch(this.showError)

            } else {

                this.$api.post(`/provider`, data)
                    .then(() => {
                        this.$router.push({ path: '/' })
                        setTimeout(_ => { this.showSuccess("New provider created") }, 500)
                    })
                    .catch(this.showError)

            }
        },
        hasRole(role){
            return this.provider && this.provider.role && this.provider.role.includes(role)
        },
        createConnection(){
            this.$refs.user_autocomplete.blur()
            EventBus().emit("dialog-create-connection:open")
        }
    }
};
</script>

<style scoped>
    .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
        max-height: 32px !important;
        min-height: 32px !important;
    }
    .append-autocomplete-btn .v-btn__content {
        justify-content: start !important;
        text-transform: none !important;
    }
</style>
