<template>
    <v-card variant="outlined" class="fill-height d-flex flex-column">
        <v-card-text>
            <p class="text-subtitle-2 mb-4"><v-chip class="mr-2" color="deep-purple" variant="outlined" size="small">PATCH</v-chip>Evse status</p>
            <p class="mb-4">Updates status of all evses in location to selected provider.</p>
            <v-autocomplete class="mb-4"
                v-model="providerToSendEvseStatus"
                :items="locationGroupProviders"
                :rules="[v => !!v]"
                :custom-filter="autocompleteDeepSearch"
                item-value="id"
                label="Send to"
                variant="outlined"
                hide-details
            >
                <template v-slot:selection="{ props, item }">
                    <v-chip v-bind="props" size="small" variant="outlined">
                        <v-icon color="black">mdi-earth</v-icon>{{ `${item.raw.country_code}-${item.raw.party_id}` }}
                    </v-chip>
                </template>
                <template v-slot:item="{ props, item  }">
                    <v-list-item v-bind="props" title>
                        <ProviderSelectorTemplate :provider="item.raw" />
                    </v-list-item>
                </template>
            </v-autocomplete>
        </v-card-text>
        
        <v-card-actions class="mb-4">
            <v-btn @click="sendEvseStatusToProvider" text block :loading="loadingSendEvseStatus" color="accent">
                <v-icon left>mdi-upload</v-icon>
                PATCH Evse status
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
export default {
    name: "send-location-tool",
    components: { ProviderSelectorTemplate },
    props: {
        locationId: {
            type: Number,
            required: true
        },
        locationGroupProviders: {
            type: Array,
            required: true
        }
    },
    inject: ['$api'],
    data() {
        return {
            providerToSendEvseStatus: null,
            loadingSendEvseStatus: false
        }
    },
    methods: {
        sendEvseStatusToProvider(){
            if( !this.providerToSendEvseStatus ){ this.showError('Provider must be selected to send evse status'); return; }

            this.loadingSendEvseStatus = true
            this.$api.get(`location/${this.locationId}/evse-status/send-to/providers/${this.providerToSendEvseStatus}`)
                .then(() => this.showSuccess('Data sent successfully'))
                .catch(() => this.showCustomAlert('error', 'Errors have occurred while sending evse status to provider', { to:`/provider/${this.providerToSendEvseStatus}/http-logs`, text:'Check PATCH calls', icon:'mdi-arrow-top-right' }))
                .then(() => { this.loadingSendEvseStatus = false })
        },
    }
}
</script>

<style scoped>
    /* Fix vuetify visual bug */
    :deep(.v-list-item--highlighted::before){
        opacity: 0 !important;
    }
    /* Fix vuetify visual bug */
    :deep(.v-list-item--highlighted.v-list-item--active::before){
        opacity: 0.12 !important;
    }
</style>