<template>
    <!-- Data to display from providers on v-autocomplete -->
    <div class="d-flex flex-nowrap align-center">
        <v-checkbox v-if="multiple" 
            class="d-flex align-center"
            color="accent" 
            :ripple="$attrs.ripple" 
            :input-value="$attrs.inputValue" 
            hide-details
        ></v-checkbox>
        
        <span>
            <strong v-if="provider.country_code && provider.party_id">{{ provider.country_code }}-{{ provider.party_id }}</strong> 
            {{name}}
        </span>

        <v-chip class="ml-1" size="small" variant="outlined" color="accent" v-if="provider.role">
            {{ provider.role }}
        </v-chip>
    </div>
</template>
  
<script>
export default {
    name: "ProviderSelectorTemplate",
    props: { 
        provider: { type: Object },
        multiple: { type: Boolean, default: false },
    },
    computed: {
        // Return the provider's name
        name() {
            return this.provider.company ? this.provider.company?.name : this.provider.user?.company?.name || this.provider.user?.username
        }
    }
  }
</script>

<style scoped>
    :deep(.v-input--selection-controls){
        margin-top: 0;
        padding-top: 0;
    }
</style>